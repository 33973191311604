
























import { Component, Mixins } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ImageExpansion from '@/components/atoms/ImageExpansion.vue'
import StudyRouting from '@/views/student/study/StudyRouting.vue'

@Component({
  components: {
    ButtonBase,
    ImageExpansion,
  },
})
export default class StudyResult extends Mixins(StudyRouting) {
  get contentSource(): string {
    return '/student/study/result/result-' + this.$route.params.id
  }

  get pointsBar(): object {
    if (Number(this.$route.params.id) === 3) {
      return [{ title: '宿題', score: '6/10' }]
    } else {
      return [
        { title: '理解度テスト', score: '6/10' },
        { title: '演習', score: '6/10' },
      ]
    }
  }
}
